var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AsyncSelect from '../AsyncSelect';
var RHFAsyncSelect = function (_a) {
    var name = _a.name, label = _a.label, helperText = _a.helperText, _b = _a.isMulti, isMulti = _b === void 0 ? false : _b, _c = _a.disable, disable = _c === void 0 ? false : _c, _d = _a.required, required = _d === void 0 ? false : _d, fetchData = _a.fetchData, placeholder = _a.placeholder, _e = _a.isSearchable, isSearchable = _e === void 0 ? true : _e, getOptionLabel = _a.getOptionLabel, getOptionValue = _a.getOptionValue, _f = _a.sx, sx = _f === void 0 ? {} : _f, value = _a.value, onChangeProp = _a.onChange, _g = _a.customContainerStyles, customContainerStyles = _g === void 0 ? {} : _g, _h = _a.customPlaceHolderStyles, customPlaceHolderStyles = _h === void 0 ? {} : _h, other = __rest(_a, ["name", "label", "helperText", "isMulti", "disable", "required", "fetchData", "placeholder", "isSearchable", "getOptionLabel", "getOptionValue", "sx", "value", "onChange", "customContainerStyles", "customPlaceHolderStyles"]);
    var control = useFormContext().control;
    var _j = useState(value), valueInput = _j[0], setValueInput = _j[1];
    useEffect(function () {
        if (JSON.stringify(valueInput) === '{}') {
            setValueInput(null);
        }
    }, [valueInput]);
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(_Fragment, { children: [_jsx(AsyncSelect, __assign({ onChange: function (e) {
                            if (!e)
                                return;
                            if (onChangeProp) {
                                onChangeProp(e);
                            }
                            else {
                                field.onChange(e);
                                setValueInput(e);
                            }
                        }, fetchData: fetchData, name: name, label: label, value: valueInput, isMulti: isMulti, disable: disable, getOptionLabel: function (option) { return getOptionLabel(option); }, getOptionValue: function (option) { return getOptionValue(option); }, isSearchable: isSearchable, placeholder: placeholder || 'select item', required: required, sx: __assign(__assign({}, sx), (error ? { border: '1px solid #F25631' } : {})) }, other, { customPlaceHolderStyles: error ? { color: '#F47C63' } : {} })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ sx: { ml: 1.5 }, error: !!error }, { children: error ? error === null || error === void 0 ? void 0 : error.message : helperText })))] }));
        } }));
};
export default RHFAsyncSelect;
